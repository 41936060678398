<template>
	<div class="container-fluid h-100 my-2">
		<div class="row justify-content-center align-items-center h-100">
			<div class="col-auto">
				<div class="box box_login">
					<div id="login" v-if="auto_connect || connecting" >
						<groomy-logo class="logo_center_bis" />
						<p class="text-center message_chargement mb-0 mt-4">
							<font-awesome-icon class="spinner_home" :icon="['far', 'spinner']" pulse /><br/>
						</p>
					</div>

					<div v-else>
						<div class="text-center mb-3">
							<groomy-logo class="logo_center_bis" />
						</div>
						<!-- <p>{{ $t('compte.acces_espace_client_groomy') }} <a href="https://www.groomy-app.com" target="_blank">Groomy®.</a></p>
						<form @submit.prevent="submitLogs">
							<div v-if="error_message" class="alert alert-warning">{{ error_message }}</div>
							<div class="form-group">
								<a href="#" class="btn btn-primary btn-block" @click.prevent="clicked_groomy = !clicked_groomy">{{ $t('compte.connexion_groomy') }}</a>
							</div>
							<template v-if="clicked_groomy">
								<div class="form-group">
									<label>{{ $t('compte.email') }}</label>
									<input type="email" class="form-control" placeholder="Votre adresse e-mail" id="mail_user" v-model="login">
								</div>
								<div class="form-group">
									<label>{{ $t("compte.password") }}</label>
									<input type="password" class="form-control" placeholder="Votre mot de passe" id="password_user" v-model="password">
								</div>
								<button id="button_submit" type="submit" title="Connexion" class="btn btn-primary rounded-pill btn-block mt-4 mb-3" :class="{disable: verrouAutoConnect}">
									{{ $t('compte.connexion') }}
								</button>
							</template>

							<router-link :to="{name: 'CompteCreation'}"  :title="$t('compte.je_nai_pas_de_compte')" class="btn btn-link btn-block btn-create">{{ $t('compte.creer_compte') }} <font-awesome-icon :icon="['fal', 'angle-right']"/></router-link>
							<router-link :to="{name: 'resetPassword'}" :title="$t('compte.j_ai_perdu_mdp')" class="btn btn-link btn-block">{{ $t('compte.mdp_oublie') }} <font-awesome-icon :icon="['fal', 'angle-right']"/></router-link>

							<div v-if="verrouAutoConnect">
								<p class="text-center"><font-awesome-icon v-if="verrouAutoConnect" :icon="['fal', 'spinner']" pulse/><br/>{{ $t('global.chargement') }} </p>
							</div>
						</form> -->

						<template v-if="step == 'mail'">
							<p class="text-center"><span v-html="getTrad('compte.acces_espace_client_groomy')"></span></p>

							<form @submit.prevent="checkMail">
								<div class="form-group">
									<label>{{ $t('compte.votre_email') }}</label>
									<input type="email" class="form-control" id="mail_user" v-model="login">
								</div>

								<div class="text-center">
									<button type="submit" class="btn btn-primary">{{ $t('global.suivant') }} <font-awesome-icon v-if="processing" pulse :icon="['fal', 'spinner']" /><font-awesome-icon v-else :icon="['fal', 'long-arrow-right']" /></button>
								</div>
							</form>
						</template>
						<template v-else-if="step == 'password'">
							<p class="text-center"><span v-html="getTrad('compte.acces_espace_client_groomy')"></span></p>

							<form @submit.prevent="submitLogs">
								<div class="form-group">
									<label>{{ $t("compte.password") }}</label>
									<input type="password" class="form-control" id="password_user" v-model="password">
									<div v-if="invalid_password" class="alert alert-warning mt-3">{{ $t('compte.invalid_password') }}</div>
								</div>

								<div class="text-center">
									<button type="submit" class="btn btn-primary">{{ $t('compte.connexion') }} <font-awesome-icon :icon="['fal', 'long-arrow-right']" /></button>
								</div>
							</form>
						</template>
						<template v-if="step == 'mail_unknown'">
							<div class="text-center">
								<div class="alert alert-warning">{{ $t('compte.mail_unknown') }}</div>
								<div class="text-center my-3 text-dark"><b>{{ login }}</b></div>
								<div class="mb-3 q_crea" v-html="getTrad('compte.question_creation')"></div>
								<button @click="goToCreate" class="btn btn-primary">{{ $t('compte.creer_compte') }} <font-awesome-icon :icon="['fal', 'user-plus']" /></button>
							</div>
						</template>
						<template v-if="step == 'creation'">
							<form @submit.prevent="validForm" class="compte-creation">
								<div class="d-flex">
									<div class="form-group pr-1">
										<label>{{ $t("compte.nom") }}</label>
										<input type="text" class="form-control" v-model="name" :class="{ 'is-invalid': errors && errors.indexOf('name') > -1 }">
									</div>

									<div class="form-group pl-1">
										<label>{{ $t("compte.prenom") }}</label>
										<input type="text" class="form-control" v-model="firstname" :class="{ 'is-invalid': errors && errors.indexOf('firstname') > -1 }">
									</div>
								</div>
								<div v-if="errors.length > 0" class="alert alert-danger">{{ $t('compte.special_char') }}</div>
								<div class="form-group phone-field">
		                            <label>{{ $t('compte.telephone') }}</label>
	                                <div class="row no-gutters">
	                                    <div class="col-auto pr-2 phone">
	                                        <phoneInput v-model="phone"></phoneInput>
	                                    </div>
	                                    <div class="col">
	                                        <div class="input-group h-100">
	                                            <div class="input-group-prepend">
	                                                <div class="input-group-text">{{ phone.phone_indicatif }}</div>
	                                            </div>
	                                            <input type="text" class="form-control h-100" v-model="phone.phone_numero">
	                                        </div>
	                                    </div>
	                                </div>
		                        </div>
		                        <div class="form-group country-field">
		                            <label>{{ $t('global.select_country') }}</label>
		                        	 <e-select
										track-by="id"
										label="name"
										:options="countries_iso_array"
										:searchable="true"
										:allow-empty="false"
										:show-labels="false"
										:search="true"
										:placeholder="$t('global.search_country')"
										:deselectLabel="$t('global.selected_label')"
										:selectedLabel="$t('global.press_enter_to_select')"
										:selectLabel="$t('global.search_country')"
										v-model="country"
									>
							            <template slot="singleLabel" slot-scope="{ option }">
							                <img v-if="option.iso" :src="require(`GroomyRoot/assets/img/flag-iso/${option.iso}.png`)">
							                <span class="ml-2">{{ option.name }}</span>
							            </template>
							            <template slot="option" slot-scope="props">
							                <img v-if="props.option.iso" :src="require(`GroomyRoot/assets/img/flag-iso/${props.option.iso}.png`)">
							                <span class="ml-2">{{ props.option.name }}</span>
							            </template>
										<template slot="noOptions">{{ $t('global.list_empty') }}</template>
									</e-select>
		                        </div>
								<div class="form-group">
									<label>{{ $t("compte.password") }}</label>
									<input type="password" class="form-control" id="password_user" v-model="password">
								</div>
								<p><i>{{ $t('compte.cgv') }}</i></p>
								<div v-if="message_erreur_code !== ''" class="alert alert-danger mt-3" v-html="getTrad(message_erreur_code)"></div>
								<div class="text-center">
									<button type="submit" class="btn btn-primary" :disabled="errors.length > 0">{{ $t('compte.creer_compte') }}  <font-awesome-icon v-if="processing" pulse :icon="['fal', 'spinner']" /><font-awesome-icon v-else :icon="['fal', 'long-arrow-right']" /></button>
								</div>
							</form>
						</template>
					</div>
				</div>

				<div v-if="step == 'mail'" class="d-flex mt-lg-5">
					<div class="col">
						<a class="h-100 langSelect" href="#" @click.prevent="setLangue('fr')">
							<span><span class="flagImg"><img :src="require(`GroomyRoot/assets/img/flag-iso/FR.png`)"></span>Français</span>
						</a>
					</div>
					<!-- <div class="col">
						<a class="h-100 langSelect" href="#" @click.prevent="setLangue('de')">
							<span><span class="flagImg"><img :src="require(`GroomyRoot/assets/img/flag-iso/DEU.png`)"></span>Deutsch</span>
						</a>
					</div>
					<div class="col">
						<a class="h-100 langSelect" href="#" @click.prevent="setLangue('it')">
							<span><span class="flagImg"><img :src="require(`GroomyRoot/assets/img/flag-iso/ITA.png`)"></span>Italiano</span>
						</a>
					</div> -->
					<div class="col">
						<a class="h-100 langSelect" href="#" @click.prevent="setLangue('en')">
							<span><span class="flagImg"><img :src="require(`GroomyRoot/assets/img/flag-iso/EN.png`)"></span>English</span>
						</a>
					</div>
					<!-- <div class="col">
						<a class="h-100 langSelect" href="#" @click.prevent="setLangue('es')">
							<span><span class="flagImg"><img :src="require(`GroomyRoot/assets/img/flag-iso/ESP.png`)"></span>Español</span>
						</a>
					</div> -->
				</div>

				<div v-if="step == 'mail_unknown'" class="text-center">
					<a href="#" @click.prevent="backToMail">{{ $t('global.retour') }}</a>
				</div>
				<div v-if="step == 'password'" class="text-center">
					<router-link :to="{name: 'askResetPassword'}" :title="$t('compte.j_ai_perdu_mdp')" class="btn btn-link btn-block">{{ $t('compte.mdp_oublie') }} <font-awesome-icon :icon="['fal', 'angle-right']"/></router-link>
					<a href="#" @click.prevent="autolog">{{ $t('compte.lien_connexion') }} <font-awesome-icon :icon="['fal', 'angle-right']"/></a>
				</div>
			</div>
		</div>
	</div>
</template>


<script type="text/javascript">
    import Vue from 'vue'
    import Constants from 'Constants'
	import UserMixin from '@/mixins/User.js'
	import Navigation from '@/mixins/Navigation.js'
	import Config from '@/mixins/Config.js'
	import { loadI18nPlugin } from 'GroomyRoot/assets/js/i18n.js'
	import Countries from '@/assets/lang/countries/countries'

	export default {
		name: "Home",
		mixins: [UserMixin, Navigation, Config],
		data () {
			return {
				reset_pwd_url: Constants.WEBAPP_GROOMY_URL + "/#/reset_password?ca=" + window.location.href,
				login: '',
                password: '',
                autolog_token: '',
                redirect: '',
				connecting: false,
				auto_connect: false,
				clicked_groomy: false,
                error_message: "",
                step: 'mail',
                processing: false,
				countries_iso_array: [],
				name: '',
				firstname: '',
				country: '',
                phone: {
                    phone_indicatif: '+33',
                    phone_numero: '',
                    phone_country: "FR"
                },
                invalid_password: false,
                errors: [],
                message_erreur_code: ''
			}
        },
		created() {
            if(this.$route.query.redirect) {
                this.redirect = this.$route.query.redirect
            }
			else {
				this.getRedirectUrl().then(res => {
					if(res.retour.redirect != null) {
						this.redirect = res.retour.redirect
					}
				})
			}
			if(this.$route.query.mail) {
				this.login = this.$route.query.mail
            }

			if(this.$route.query.lastname) {
				this.name = this.$route.query.lastname
				this.step = 'creation'
            }

			if(this.$route.query.firstname) {
				this.firstname = this.$route.query.firstname
				this.step = 'creation'
            }

			if(this.$route.query.phone) {
				this.phone.phone_numero = this.$route.query.phone
				this.step = 'creation'
            }

            if(this.$route.query.als) {
                this.auto_connect  = true
                this.autolog_token = this.$route.query.als
                
                this.submitAutoLog(this.login, this.autolog_token)
                    .then(() => {
                        this.redirectPostLogin(this.redirect)
                    })
                    .catch(() => {
                        this.returnToLogin()
                    })
            }
            this.loadCountries()
		},
		methods: {
			async submitLogs() {
				if(this.login != '' && this.password != '') {
					this.connecting = true
					this.invalid_password = false

                    try {
						await this.loginUser(this.login, this.password, this.redirect, true)
                    } 
                    catch (err) {
						this.error_message = err.message
						this.invalid_password = true
						this.returnToLogin()
					}
				}
			},
			returnToLogin() {
				this.auto_connect = false
				this.connecting = false
			},
			async checkMail() {
				this.processing = true
				let exist = await this.checkIfAdressMailExists(this.login)
				this.processing = false
				if(exist) {
					this.step = 'password'
				} else {
					this.step = 'mail_unknown'
				}
			},
			setLangue(lang) {
				this.setConfig('lang', lang)
				loadI18nPlugin(lang)
				this.$emit('langue_choosed')
			},
			backToMail() {
				this.step = 'mail'
			},
			goToCreate() {
				this.step = 'creation'
			},
			loadCountries() {
				this.countries = Countries[Vue.i18n.locale()].label
				this.countries_iso = Countries[Vue.i18n.locale()].iso_3

				let countries_values = Object.values(this.countries)
				let countries_iso_values = Object.values(this.countries_iso)

				let temp = []
				for (let i = 0; i < countries_values.length; i++) {
					temp.push(countries_values[i])
				}

				let countries_iso_array_temp = []
				let countries_keys = Object.keys(this.countries)
				let countries_formatted = Object.fromEntries(countries_keys.map((_, i) => [countries_keys[i], temp[i]]))

				for (let [key, value] of Object.entries(countries_formatted)){
					countries_iso_array_temp.push({
						id: key,
						name: value,
						iso: this.countries_iso[key]
					})
				}
				this.countries_iso_array = countries_iso_array_temp.sort((a,b) => {
					let x = this.accentsTidy(a.name.toLowerCase());
				    let y = this.accentsTidy(b.name.toLowerCase());
				    return x < y ? -1 : x > y ? 1 : 0;
				});

				this.country = countries_iso_array_temp.find(country => country.iso == "FRA")

				if(this.$route.query.country) {
					this.country = countries_iso_array_temp.find(country => country.id == this.$route.query.country)
				}
			},
			async validForm() {
                let user = {
                    name: this.name,
                    firstname: this.firstname,
                    mail: this.login,
                    password: this.password,
                    password_confirmation: this.password,
                    indicatif: this.phone.phone_indicatif,
                    phone: this.phone.phone_numero,
                    // Code ISO à 3 lettre ne fonctionne pas pour les numéros de téléphone, merci à tous
                    country: this.phone.phone_country.slice(0, 2)
                }
                this.message_erreur_code = ''

				this.processing = true
                let res = await this.createUser(user, 'client_area')
                if(res.code_retour) {
                    // this.$cache.user_id = res.retour.id
                    await this.setConfig("user_id", res.retour.id)

                    const result_login = await this.checkLogin(this.login, this.password)
                    if(result_login){
                        this.$router.push({ name: 'EmailValidation' })
                    }
                }
                else {
                    this.message_erreur_code = res.retour
                }
				this.processing = false
            },

            async autolog() {
            	const lang = this.getConfig('lang') ?? 'fr'
            	await this.sendAutolog(this.login, lang)
            	this.successToast('toast.autolog_send')
            },

            checkSpecialChar() {
            	this.errors = []
				var format = /^[A-Z- ]*$/;
				if(!format.test(this.name)) {
					this.errors.push('name')
				}
				if(!format.test(this.firstname)) {
					this.errors.push('firstname')
				}
            }
		},
		computed: {
			verrouAutoConnect: function() {
				return this.auto_connect || this.connecting
			},
			verrouManualConnect: function() {
				return this.connecting
			}
		},
		watch: {
			name(val) {
				this.name = val.toUpperCase()
				this.checkSpecialChar()
			},
			firstname(val) {
				this.firstname = val.toUpperCase()
				this.checkSpecialChar()
			}
		},
        components: {
            PhoneInput : () => import('GroomyRoot/components/Inputs/PhoneInput'),
        }
	};
</script>
